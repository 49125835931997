import React from "react";
import { graphql } from "gatsby";

import PageTitle from "../components/page-title";
import Seo from "../components/seo";
import ExcerptPanels from "../components/excerpt-panels";
import { getSrc } from "gatsby-plugin-image";

export default function ProjectsIndex({data}) {

    const projects = data.projects.edges.map(obj => {
        let rObj = {};
        rObj.path = obj.node.frontmatter.path;
        rObj.title = obj.node.frontmatter.title;
        rObj.image = getSrc(obj.node.frontmatter.image);
        rObj.imageAlt = obj.node.frontmatter.imageAlt;
        rObj.logo = obj.node.frontmatter.logo.publicURL;
        rObj.summary = obj.node.frontmatter.summary;
        return rObj
    });

    return(
        <>
            <Seo
                title="Projects"
                description="Review our previous projects delivered on the AWS cloud, from large scale migrations to consultancy, optimisation and management. We're able to help whatever stage your organisation is at with AWS cloud adoption."
            />

            <PageTitle summary="We're ready to help at any stage of your journey to the cloud, we're able to draw on our vast experience with the AWS platform to deliver on and within budget for even the most challenging of projects."
                       title="Projects"/>

            <div className="mb-10 lg:mb-32 max-w-screen-xl mx-auto px-6 md:px-12">
                <div className="lg:text-center">
                    <h3 className={"mt-2 text-3xl leading-8 font-bold tracking-tight text-indigo-600 sm:text-4xl sm:leading-10"}>The clients we serve</h3>
                    <p className="mt-4 mb-4 lg:mb-10 max-w-2xl prose prose-lg text-gray-700 lg:mx-auto">
                        Here's a selection of some of our recent client projects:
                    </p>
                </div>
                <ExcerptPanels excerpts={projects}/>
            </div>

        </>
    )
}

export const query = graphql`{
  projects: allMarkdownRemark(
    filter: {fileAbsolutePath: {glob: "**/content/projects/*.md"}}
  ) {
    edges {
      node {
        frontmatter {
          path
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 500, layout: FIXED)
            }
          }
          imageAlt
          logo {
            publicURL
          }
          summary
        }
      }
    }
  }
}
`;
